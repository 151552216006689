import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../common/Loading";
import * as api from "../../api/api"
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";
import * as Global from '../../common/Global'

const SplashView = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [account, setAccount] = useState("")

    const login = () => {
        if (account.length > 0) {
            const params = {
                cmpId: Global.cmpId,
                cardNo: account,
                isUse: 1
            }

            setLoading(true)
            api.getUserCard(params).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    if (Object.keys(data).length > 0) {
                        console.log("### card :: ", data)
                        props.setAccount(data)
                        // 판매점 정보 확인
                        if (data.id === 0) {
                            window.alert("카드 등록이 필요합니다.")
                            navigate("/card/register")
                        }
                        else {
                            navigate("/home")
                        }
                    }
                    else {
                        window.alert("등록되지 않은 카드입니다.")
                    }
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className={`flex flex-col justify-center items-center bg-gradient-to-b from-[${Global.foreColor1}] to-[${Global.foreColor2}]`}>
        {/*<div className={`flex flex-col justify-center items-center bg-gradient-to-b from-[#333333] to-[#999999]`}>*/}
            {loading ? <Loading /> : null}
            <div className={"flex flex-col w-[390px] h-screen p-5 gap-4 items-center justify-center"}>
                {Global.imgLogo}

                <div className={"h-16"} />

                <div className={"flex flex-col"}>
                    <span className={"text-lg text-white font-bold text-center"}>
                        {Global.message1}
                </span>
                    <span className={"text-lg text-white font-bold text-center"}>
                        {Global.message2}
                    </span>
                </div>

                <div className={"h-12"} />

                <input className={"w-full border-b-2 p-2 border-white-800 bg-transparent text-center " +
                "text-white text-lg font-bold " +
                "placeholder:text-white placeholder:text-lg"}
                       placeholder={"your card number"}
                       value={account}
                       onChange={event => {
                           setAccount(event.target.value)
                       }}
                />

                <button className={"w-full h-[60px] bg-white rounded-lg font-bold"} onClick={login}>
                    로그인 / 회원가입
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account))
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashView)